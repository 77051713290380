import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FLOW_CADENCE_ADVANTAGES } from 'views/flow-cadence/objective-advantages-section/constants';
import { AdvantagesSectionTemplate } from 'components/advantages-section-template';
import { Container } from 'components/container';

const SLink = styled.a`
    color: var(--primary-color);
`;

export const ObjectiveAdvantagesSection = () => {
    return (
        <Container>
            <AdvantagesSectionTemplate
                title="flow-cadence.whyChooseFlowBlockchain"
                firstParagraph={
                    <FormattedMessage
                        id="flow-cadence.flowBlockchainDeveloped"
                        values={{
                            dapperLabsLink: (chunks) => (
                                <SLink
                                    href="https://www.dapperlabs.com/"
                                    target="_blank"
                                >
                                    {chunks}
                                </SLink>
                            ),
                            nbaLink: (chunks) => (
                                <SLink
                                    href="https://nbatopshot.com/"
                                    target="_blank"
                                >
                                    {chunks}
                                </SLink>
                            ),
                        }}
                    />
                }
                advantages={FLOW_CADENCE_ADVANTAGES}
                areAdvantagesTogglable={false}
            />
        </Container>
    );
};
